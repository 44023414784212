<template>
    <transition name="fade">
        <div class="modal" v-if="show">
            <div class="modal__backdrop" @click="closeModal()"/>

            <div class="modal__dialog">
                <div class="modal__header">
                    <slot name="header"/>
                </div>

                <div class="modal__body">
                    <slot name="body"/>
                </div>

                <div class="modal__footer">
                    <slot name="footer"/>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "Modal",
        data() {
            return {
                show: false
            };
        },
        methods: {
            closeModal() {
                this.show = false;
                document.querySelector("body").classList.remove("overflow-hidden");
            },
            openModal() {
                this.show = true;
                document.querySelector("body").classList.add("overflow-hidden");
            }
        }
    };
</script>


<style lang="scss" scoped>
    @import "src/assets/css/colors";
    @import "src/assets/css/mixins";

    .modal {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1100;
        overflow-x: hidden;
        overflow-y: auto;
        display: block;
        text-align: left !important;
        &__backdrop {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba($button-primary-rgb, 0.3);
            z-index: 1;
        }
        &__dialog {
            position: relative;
            width: 600px;
            background-color: $secondary-background;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            z-index: 2;
            @include media-max(md) {
                width: 90%;
            }
            border: 1px solid rgba($button-primary-rgb,.2);
            max-width: 600px;
            margin: 30px auto;
            line-height: 1.5;
            color: $text-tertiary-dark;
        }
        &__close {
            width: 30px;
            height: 20px;
            justify-content: flex-end;
        }
        &__header {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            padding: 15px 15px 10px;
            border-bottom: 1px solid $text-tertiary-light;
            border-top-left-radius: .3rem;
            border-top-right-radius: .3rem;
            display: -webkit-box;
            display: -ms-flexbox;
            -webkit-box-align: start;
            -ms-flex-align: start;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            border-bottom: 1px solid $text-tertiary-light;

        }
        &__footer {
            padding: 10px ;
            border-top: 1px solid $text-tertiary-light;
        }

    }
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.2s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

</style>
