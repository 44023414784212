<template>
	<div>
		<checkbox-component :name="name" @checkbox:change="onChange" :error="error">
			<p>
				I Accept the
				<span @click.stop.prevent="modalForPage('terms_conditions')">terms & conditions</span>
				and
				<span @click.stop.prevent="modalForPage('privacy_policy')">privacy policy</span> *
			</p>
		</checkbox-component>
		<modal ref="modalForPage">
			<template v-slot:header>
				<h1 class="modal__title">{{pageTitle}}</h1>
			</template>

			<template v-slot:body>
				<div class="wrapper">
					<div v-html="pageContent"></div>
				</div>
			</template>

			<template v-slot:footer>
				<div class="close_wrapper">
					<button class="btn" @click.stop.prevent="$refs.modalForPage.closeModal()">Close</button>
				</div>
			</template>
		</modal>
	</div>
</template>

<script>
  import CheckboxComponent from "./CheckboxComponent";
  import Modal from "../Modal";
  import {mapActions, mapState} from "vuex";
  export default {
    name: "TermsCheckbox",
    components: {Modal, CheckboxComponent},
    props:{
      name: {
        type: String,
        default: 'terms'
      },
      error: {
        type: String
      }
    },
    data(){
      return {
        pageContent: null,
        pageTitle: null,
      }
    },
    computed:{
      ...mapState({
        channelPages: state => state.channel.pages,
      }),
    },
    methods:{
      ...mapActions(['GET_CHANNEL_PAGE']),
      onChange(e){
        this.$emit('checkbox:change', {
          name: this.name,
          value: e.target.checked
        })
      },
      modalForPage(url) {
        let id = null;
        this.channelPages.forEach(page => {
          if (page.url == url || page.url == '/' + url || page.alt_name == url) {
            id = page.id;
          }
        });

        if (id != null) {
          this.GET_CHANNEL_PAGE(id)
              .then((data) => {
                const {content, name = ''} = data?.data;
                this.pageContent = this.convertEditorJsonToHtml(content) ?? '';
                this.pageTitle = name;
                this.$refs.modalForPage.openModal();
              });
        } else {
          this.pageContent = "Add page for your channel with URL or Alternative name " + url;
          this.pageTitle = "Error!";
          this.$refs.modalForPage.openModal();
        }

      },
    }
  }
</script>

<style scoped lang="scss">
	@import "src/assets/css/colors";
	p{
		margin-left: 1rem;
	}
	span{
		color: $link-accent;
		cursor: pointer;
	}
</style>